<template>
  <a-row type="flex" class="pa-3 background_color">
    <a-col :span="24" :md="6">
      <a-row type="flex" justify="center">
        <v-avatar size="200">
          <img :src="`${pathPicture}${profile}`" />
        </v-avatar>
      </a-row>
    </a-col>
    <a-col :span="24" :md="8" class="mt-5">
      <span class="title">{{ dataUser.firstName }} {{ dataUser.lastName }} {{ dataUser.age }} {{ dataUser.gender === 'FEMALE' ? 'F' : 'M'}}</span><br>
      <span class="pl-5">จังหวัด : {{ dataUser.province }}</span><br>
      <span class="pl-5">ตำเเหน่งอาชีพ : {{ jobName }}</span><br>
      <span class="pl-5">ลักษณะบริการ : {{ serviceType }}</span><br>
      <span class="pl-5">ค่าบริการ : {{ dataUser.minFee }} - {{ dataUser.maxFee }} {{ feeCurrency }}/{{ feeFrequency }}</span><br>
    </a-col>
    <a-col :span="24" :md="10" class="mt-15">
      <a-row type="flex" justify="center">
        <a-button @click="OpenFlag" v-if="!isPublic"> เปิดโปรไฟล์เเละบริการสู่สาธารณะ <a-icon type="eye" /></a-button>
        <a-button @click="OpenFlag" v-else> ปิดโปรไฟล์เเละบริการสู่สาธารณะ <a-icon type="eye" /></a-button>
      </a-row>
      <a-row type="flex" justify="center" class="mt-3">
        <a @click="EditUserProfile()">เเก้ไขโปรไฟล์</a>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { decode } from '@/services/decode'
import { encode } from '@/services/encode'
export default {
  data () {
    return {
      dataUser: [],
      user_id: '',
      jobName: '',
      serviceType: '',
      feeCurrency: '',
      feeFrequency: '',
      pathPicture: process.env.VUE_APP_IMG,
      profile: '',
      isPublic: false
    }
  },
  async created () {
    this.getDetailMe()
  },
  methods: {
    async getDetailMe () {
      if (Object.prototype.hasOwnProperty.call(localStorage, 'User')) {
        var data = JSON.parse(decode(localStorage.getItem('User')))
        console.log('data', data.user)
        this.dataUser = data.user
        this.jobName = this.dataUser.job.name
        this.isPublic = this.dataUser.isPublic
        this.serviceType = this.dataUser.serviceType.name
        this.feeCurrency = this.dataUser.feeCurrency.name
        this.feeFrequency = this.dataUser.feeFrequency.name
        this.profile = this.dataUser.profileImages[0].file.path
      } else {
        this.$swal.fire({ icon: 'warning', title: 'ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่', showConfirmButton: false, timer: 1500 })
      }
    },
    EditUserProfile () {
      var data = JSON.parse(decode(localStorage.getItem('User')))
      if (data.user.role === 'USER') {
        this.$router.push({ path: '/step1?status=service&&type=edit' }).catch(() => {})
      } else {
        this.$router.push({ path: '/step1?status=provider&&type=edit' }).catch(() => {})
      }
    },
    async OpenFlag () {
      const data = JSON.parse(decode(localStorage.getItem('User')))
      var val = {
        isPublic: !data.user.isPublic
      }
      const auth = {
        headers: { Authorization: `Bearer ${data.token}` }
      }
      await this.axios.put(`${process.env.VUE_APP_API}users/${data.user.id}`, val, auth)
      var res = await this.axios.get(`${process.env.VUE_APP_API}users/${data.user.id}`, auth)
      if (res.data.response_status === 'SUCCESS') {
        if (this.isPublic) {
          this.$swal.fire({ icon: 'success', title: 'เปิดบริการสู่สาธารณะสำเร็จ', showConfirmButton: false, timer: 1500 })
        } else {
          this.$swal.fire({ icon: 'success', title: 'ปิดบริการสู่สาธารณะสำเร็จ', showConfirmButton: false, timer: 1500 })
        }
        // console.log('test edit isPublic', res.data)
        data.user =  res.data.data
        localStorage.setItem('User', encode(data))
        this.getDetailMe()
      } else {
        this.$swal.fire({ icon: 'warning', title: 'เปิดบริการสู่สาธารณะไม่สำเร็จ', showConfirmButton: false, timer: 1500 })
      }
    }
  }
}
</script>

<style scoped>
.background_color{
  background-color: rgb(216, 211, 183) !important;
}
</style>
