<template>
  <div>
    <v-dialog v-model="ModalDetailUser" max-width="85%" scrollable persistent>
      <v-card
      width="100%"
      height="100%"
      outlined
      elevation="0"
      dense
      style="background-color: transparent;"
      >
        <v-toolbar style="background-color: transparent;" flat>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()"><v-icon large color="LightGray">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- <pre>{{dataUser}}</pre> -->
          <v-card
          width="100%"
          height="100%"
          outlined
          dense
          color="#D4C3B2"
          class="pa-2"
          >
            <v-card-text>
              <v-card
              width="100%"
              height="100%"
              outlined
              elevation="0"
              dense
              color="#1C2C3C"
              >
              <v-card-text>
                <v-container>
                    <v-row dense justify="center" align="center">
                      <v-col cols="12" md="4">
                        <v-carousel
                        :continuous="false"
                        :cycle="cycle"
                        :show-arrows="false"
                        hide-delimiter-background
                        height="350"
                        class="px-auto"
                        >
                          <v-carousel-item
                          v-for="(item, index) in dataUser.profileImages"
                          :key="index"
                          >
                            <v-sheet
                            height="100%"
                            width="100%"
                            tile
                            >
                              <!-- <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                              > -->
                                <div class="display-3">
                                  <v-img :src="`${pathPicture}${item.file.path}`" max-height="300" contain></v-img>
                                </div>
                              <!-- </v-row> -->
                            </v-sheet>
                          </v-carousel-item>
                        </v-carousel>
                      </v-col>
                      <v-col cols="12" md="7" class="mx-2">
                        <h1 style="font-weight: bold; font-style: italic; color: rgb(190, 181, 151);">{{ dataUser.firstName }} {{ dataUser.lastName }} {{ dataUser.age }} {{ dataUser.gender === 'FEMALE' ? 'F' : 'M'}}</h1>
                        <v-divider dark></v-divider>
                        <v-card color="#C1BCA9" dense outlined class="mt-4 my-2">
                          <v-card-text>
                            <div v-if="isView === false">
                              <v-btn color="#FAF1D1" block @click="dialog = !dialog">
                                <v-icon color="#A55147">mdi-phone-in-talk</v-icon>
                                <span class="pl-4" style="color: #A55147;">คลิ๊กเพื่อติดต่อกับ</span><span style="color: #A55147; font-weight: bold;">{{ dataUser.firstName }} {{ dataUser.lastName }}</span>
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-icon color="#A55147">mdi-phone</v-icon> <span style="color: #1B2229;">เบอร์โทรศัพท์ : {{ dataUser.telCode }}{{ dataUser.telNumber }}</span>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense class="pl-1">
                                    <v-img src="@/assets/LINE_APP.png" max-height="24" max-width="24"></v-img> <span class="pl-2" style="color: #1B2229; font-weight: bold;">Line ID : {{ dataUser.line }}</span>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row align="center" justify="center">
                                    <v-col cols="12" md="6">
                                      <v-btn
                                        color="#1B2229"
                                        block
                                        outlined
                                      >
                                        <v-icon color="#1B2229">mdi-forum</v-icon><span style="color: #1B2229;">แชทเลย</span>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-btn
                                        color="#1B2229"
                                        block
                                        outlined
                                        @click="dialog = false"
                                      >
                                        <span style="color: #A55147;">ยื่นข้อเสนอ</span>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-row dense class="mt-4">
                          <v-icon color="rgb(190, 181, 151)">mdi-gender-male-female</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">เพศ : {{ dataUser.gender === 'FEMALE' ? 'หญิง' : 'ชาย'}}</span>
                          <v-icon color="rgb(190, 181, 151)" class="pl-4">mdi-calendar-month</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">อายุ : {{ dataUser.age }} ปี</span>
                        </v-row>
                        <v-row dense class="mt-4">
                          <v-icon color="rgb(190, 181, 151)">mdi-head</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">โรคประจำตัว : {{ dataUser.allergy }}</span>
                        </v-row>
                        <div v-if="dataUser.role === 'USER'">
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-briefcase</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ตำแหน่งอาชีพที่ต้องการ : {{ dataUser.job.name }}</span>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-briefcase</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ลักษณะการรับบริการที่ตามหา : {{ dataUser.serviceType.name }}</span>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-currency-usd</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ค่าบริการที่ตามหา : {{ dataUser.minFee }} - {{ dataUser.maxFee }} {{ dataUser.feeCurrency.name }}/{{ dataUser.feeFrequency.name }}</span>
                          </v-row>
                        </div>
                        <div v-else-if="dataUser.role === 'EXPERT'">
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-briefcase</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ตำแหน่งอาชีพ : {{ dataUser.job.name }}</span>
                            <v-icon color="rgb(190, 181, 151)">mdi-school</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ระดับการศึกษา : {{ dataUser.education.name }}</span>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-school</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ประกาศณียบัตร : {{ dataUser.license.name }} เลขที่ {{ dataUser.licenseNo }}</span>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-bank</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">สถาบัน : {{ dataUser.school }} เลขที่ {{ dataUser.licenseNo }}</span>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-icon color="rgb(190, 181, 151)">mdi-currency-usd</v-icon><span style="color: rgb(190, 181, 151);" class="pl-4">ค่าบริการ : {{ dataUser.minFee }} - {{ dataUser.maxFee }} {{ dataUser.feeCurrency.name }}/{{ dataUser.feeFrequency.name }}</span>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters justify="center" align="center" v-if="dataUser.role === 'USER'">
                      <v-container fluid>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ข้อมูลผู้รับบริการ</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-textarea v-model="dataUser" counter :maxlength="maxText" solo></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ทักษะที่ต้องการ</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0" v-for="(item, index) in dataUser.userSkills" :key="index">
                          <v-row dense class="pt-0 pb-0">
                            <v-checkbox v-model="item.skill.activeFlag" color="success"></v-checkbox><span class="mt-5" style="color: rgb(190, 181, 151);">{{ item.skill.name }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ลักษณะการบริการที่ตามหา/คำขอพิเศษ</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-textarea v-model="dataUser" counter :maxlength="maxText" solo></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ตารางงาน</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <a-button shape="circle" size="large" v-for="item in ListDay" :key="item.index" :class="item.status === true ? 'green' : 'white'" @click="item.status = !item.status">{{item.name}}</a-button>
                        </v-col>
                      </v-container>
                    </v-row>
                    <v-row dense no-gutters justify="center" align="center" v-else-if="dataUser.role === 'EXPERT'">
                      <v-container fluid>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ประสบการณ์ทำงานและการบริการ</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <v-textarea v-model="dataUser.experience" counter :maxlength="maxText" solo></v-textarea>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ทักษะที่สามารถทำได้</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0" v-for="(item, index) in dataUser.userSkills" :key="index">
                          <v-row dense class="pt-0 pb-0">
                            <v-checkbox v-model="item.skill.activeFlag"  color="success" readonly></v-checkbox><span class="mt-5" style="color: rgb(190, 181, 151);">{{ item.skill.name }}</span>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="12">
                          <h3 style="font-weight: bold; color: rgb(190, 181, 151);">ตารางงาน</h3>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                          <a-button shape="circle" size="large" v-for="item in ListDay" :key="item.index" :class="item.status === true ? 'green' : 'white'" @click="item.status = !item.status">{{item.name}}</a-button>
                        </v-col>
                      </v-container>
                    </v-row>
                </v-container>
              </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
     v-model="dialog"
     persistent
     max-width="290"
    >
      <v-card color="#FBF3D3">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false"><v-icon large color="#737470">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text style="text-align: center;">คุณต้องการดูรายละเอียดการติดต่อ และ แชทกับ <span style="font-weight: bold;">{{ dataUser.firstName }} {{ dataUser.lastName }}</span> ใช่ หรือ ไม่?</v-card-text>
        <v-card-actions>
          <v-row align="center" justify="center" dense>
            <v-col cols="12" md="6">
              <v-btn
                color="#1B2229"
                block
                @click="ComfirmCheckUser()"
              >
                <span style="color: #FBF3D3;">ยืนยัน</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                color="#C3BC9C"
                block
                @click="dialog = false"
              >
                <span style="color: #1B2229;">ยกเลิก</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { decode } from '@/services/decode'
export default {
  props: ['dataUser'],
  data () {
    return {
      pathPicture: process.env.VUE_APP_IMG,
      maxText: 250,
      cycle: false,
      ListDay: [
        { index: 0, name: 'อา', status: false},
        { index: 1, name: 'จ', status: false},
        { index: 2, name: 'อ', status: false},
        { index: 3, name: 'พ', status: false},
        { index: 4, name: 'พฤ', status: false},
        { index: 5, name: 'ศ', status: false},
        { index: 6, name: 'ส', status: false}
      ],
      dialog: false,
      checkComfirm: false,
      isView: false
    }
  },
  watch: {
    dataUser (val) {
      var scheduleDate = JSON.parse(val.schedule)
      this.setListDay()
      scheduleDate.forEach(element => {
        this.ListDay[element].status = true
      })
      this.CheckProfileLog()
    }
  },
  computed: {
    ModalDetailUser () {
      return this.$store.state.ModalDetailUser
    }
  },
  methods: {
    close () {
      this.$store.commit('SetModalDetailUser')
    },
    setListDay () {
      this.ListDay = [
        { index: 0, name: 'อา', status: false},
        { index: 1, name: 'จ', status: false},
        { index: 2, name: 'อ', status: false},
        { index: 3, name: 'พ', status: false},
        { index: 4, name: 'พฤ', status: false},
        { index: 5, name: 'ศ', status: false},
        { index: 6, name: 'ส', status: false}
      ]
    },
    async CheckProfileLog () {
      var dataUserMe = JSON.parse(decode(localStorage.getItem('User')))
      var data = {
        userId: dataUserMe.user.id,
        viewUserId: this.dataUser.id
      }
      await this.$store.dispatch('ActionsGetProfileLog', data)
      var response = await this.$store.state.GetProfileLog
      // console.log(response)
      if (response.status === 200) [
        this.isView = response.data.isView
      ]
    },
    async ComfirmCheckUser () {
      var dataUserMe = JSON.parse(decode(localStorage.getItem('User')))
      var data = {
        userId: dataUserMe.user.id,
        viewUserId: this.dataUser.id
      }
      await this.$store.dispatch('ActionsSetProfileLog', data)
      var response = await this.$store.state.SetProfileLog
      console.log(response)
      if (response.status === 200) {
        this.dialog = false
        this.CheckProfileLog()
      }
    }
  }
}
</script>

<style scoped>
.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0px;
  max-height: 0px;
  border: solid;
  border-width: thin 1 0 0 !important;
  transition: inherit;
  color: rgb(190, 181, 151) !important;
}
</style>
