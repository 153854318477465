<template>
<div class="pa-10">
  <a-row type="flex">
    <span class="headline ma-5" style="color:white">ตามหาตำเเหน่งอาชีพ</span>
  </a-row>
  <a-row type="flex" class="pa-5 mb-5 background_color_list" v-for="(item,index) in datalistUser" :key="index">
    <a-col :span="24" :md="6">
      <a-row type="flex" justify="center">
        <v-avatar size="150">
          <img :src="`${pathPicture}${item.profileImages[0].file.path}`" />
        </v-avatar>
      </a-row>
    </a-col>
    <a-col :span="24" :md="14" class="mt-0">
      <span class="title">{{ item.firstName }} {{ item.lastName }} {{ item.age }} {{ item.gender === 'FEMALE' ? 'F' : 'M'}}</span><br>
      <span class="pl-5">จังหวัด : {{ item.province }}</span><br>
      <span class="pl-5">ตามหาบริการ : {{ item.serviceType.name }}</span><br>
      <span class="pl-5">ตามหา : {{ item.job.name }}</span><br>
      <span class="pl-5">ค่าบริการ : {{ item.minFee }} - {{ item.maxFee }} {{ item.feeCurrency.name }}/{{ item.feeFrequency.name }}</span><br>
    </a-col>
    <a-col :span="24" :md="4" class="mt-8">
      <a-row type="flex" justify="center">
          <a-button @click="DetailUser(item)"> ดูโปรไฟล์ <a-icon type="eye" /></a-button>
      </a-row>
    </a-col>
  </a-row>
  <ModalUserDetail :dataUser="dataUser"/>
</div>
</template>

<script>
import { decode } from '@/services/decode'
import ModalUserDetail from '@/components/ManageUser/ModalDetailUser'
export default {
  components: {
    ModalUserDetail
  },
  data () {
    return {
      datalistUser: [],
      pathPicture: process.env.VUE_APP_IMG,
      dataUser: []
    }
  },
  async created () {
    // var data = JSON.parse(decode(localStorage.getItem('User')))
    // this.user_id = data.user.id
    this.getListUser()
  },
  methods: {
    async getListUser () {
      await this.$store.dispatch('GetListUser')
      var response = await this.$store.state.DataListUser
      if (response.data.response_status === 'SUCCESS') {
        const data = JSON.parse(decode(localStorage.getItem('User')))
        this.datalistUser = response.data.data.filter(element => {
          return element.id !== data.user.id
        })
        console.log('data listuser clean====>', this.datalistUser)
      } else {
        this.$swal.fire({ icon: 'warning', title: 'ไม่มีข้อมูลคนไข้ โปรดติดต่อเจ้าหน้าที่', showConfirmButton: false, timer: 1500 })
      }
      // console.log('data User=====>', this.datalistUser.data.data)
    },
    async DetailUser (item) {
      this.dataUser = item
      await this.$store.commit('SetModalDetailUser')
    }
  }
}
</script>
<style scoped>
.background_color_list{
  background-color: rgb(212, 213, 217) !important;
  border-radius: 20px;
}
</style>