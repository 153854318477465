<template>
  <div>
    <v-container>
      <v-card  max-width="100%" color="rgb(211, 211, 211, 0.9)">
        <div class="background_color_me">
          <Me />
        </div>
        <div class="background_color_listuser">
          <ListUser />
        </div>
      </v-card>
    </v-container>
    <ProviderModal />
  </div>
</template>
<script>
import Me from '@/components/ManageUser/Me';
import ListUser from '@/components/ManageUser/ListUser';
import ProviderModal from '@/components/User/Modal/ModalProvider'
export default {
  components: {
    Me,
    ListUser,
    ProviderModal
  },
  created () {
    this.showModal()
  },
  methods: {
    showModal () {
      // console.log('123456')
      this.$store.commit('SetModalProvider')
    }
  }
}
</script>
<style scoped>
.background_color_me{
  background-color: rgb(216, 211, 183) !important;
}
.background_color_listuser{
  background-color: rgb(31, 44, 61) !important;
}
</style>