<template>
  <v-dialog v-model="ModalProvider" max-width="470">
    <v-card
     max-width="470"
     max-height="100%"
     outlined
     dense
     color="rgb(128, 128, 128, 0.8)"
     class="pb-2"
    >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()"><v-icon large>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-card
        class="mx-4 mt-0"
        max-height="100%"
        max-width="400"
        outlined
        color="rgb(26, 38, 53)"
        >
          <v-card-title class="mt-2">
            <v-row justify="center">
              <span style="color: rgb(190, 181, 151)">อัพเกรดเป็น <span style="font-weight: bold; font-style: italic;">Health Express Pro</span></span>
            </v-row>
          </v-card-title>
          <v-col cols="12" md="12" class="mt-4 mb-2">
            <v-row justify="center" align="center">
              <v-avatar size="164">
                <img
                alt="Avatar"
                src="https://picsum.photos/id/11/500/300"
                >
              </v-avatar>
            </v-row>
          </v-col>
          <v-card-text>
            <span style="color: rgb(190, 181, 151)">- ไม่มีโฆษณา</span><br/>
            <span style="color: rgb(190, 181, 151)">- กดดูโปรไฟล์ของคู่บริการได้ไม่จำกัด</span><br/>
            <span style="color: rgb(190, 181, 151)">- โปรไฟล์ของคุณหาเจอง่าย เพราะขึ้นก่อนใคร</span><br/>
            <span style="color: rgb(190, 181, 151)">- สร้างความน่าเชื่อถือของคุณด้วยระบบ <span style="font-weight: bold; font-style: italic;">Verifycation</span></span><br/>
            <span style="color: rgb(190, 181, 151)">- และอื่นๆอีกมากมายของ <span style="font-weight: bold; font-style: italic;">Health Express Pro</span></span><br/>
            <span style="color: rgb(190, 181, 151)">หาผู้เชี่ยวชาญที่เหมาะกับคุณ เพียงเดือนละ <span style="font-weight: bold; font-style: italic;">120</span> บาท</span>
          </v-card-text>
          <v-container>
            <v-btn color="rgb(192, 16, 24)" block class="mb-2 mt-0 white--text" @click="GotoVerify()">สมัครเลย</v-btn>
            <v-btn color="rgb(69, 75, 95)" block class="white--text" @click="closeModal()">ยังก่อน</v-btn>
          </v-container>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    ModalProvider () {
      return this.$store.state.ModalProvider
    }
  },
  methods: {
    closeModal () {
      this.$store.commit('SetModalProvider')
    },
    GotoVerify () {
      this.$router.push({ path: '/registerverify' }).catch(() => {})
    }
  }
}
</script>

<style>

</style>
